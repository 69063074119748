<template>
  <div class="">
    <el-card shadow="never">
      <el-button type="primary" @click="addModel">添加模型</el-button>
      <commonTable :tableData="tableData" :loading="loading" @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange" :currentPage="currentPage" :total="total">
        <template v-slot:table>
          <el-table-column prop="id" align="center" label="编号" width="80px" />
          <el-table-column prop="title" align="center" label="名称" width="200px" />
          <el-table-column prop="typeName" align="center" label="分类" width="200px" />
          <el-table-column prop="realUrl" align="center" label="文件地址" />
          <el-table-column prop="userName" align="center" label="创建时间">
            <div slot-scope="scope">
              {{ setDataTimeSec(scope.row.createTime) }}
            </div>
          </el-table-column>
          <el-table-column prop="remark" align="center" label="备注" />
          <el-table-column align="center" label="操作">
            <div slot-scope="scope">
              <el-button type="text" @click="editModel(scope.row)">设置</el-button>
              <el-popconfirm class="ml-12" title="是否删除分类？" @confirm="dalete(scope.row.id)">
                <el-button slot="reference" type="text">删除</el-button>
              </el-popconfirm>
            </div>
          </el-table-column>
        </template>
      </commonTable>
    </el-card>
    <el-dialog title="添加模型" :visible.sync="dialogVisible" width="50%" :modal-append-to-body="false"
      :destroy-on-close="true" center>
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="78px">
        <el-form-item label="名称">
          <el-input v-model="ruleForm.title" placeholder="请输入模型名称"></el-input>
        </el-form-item>
        <el-form-item label="分类" prop="title">
          <el-select v-model="ruleForm.typeId" placeholder="请选择模型分类" style="width: 100%">
            <el-option v-for="item in classList" :key="item.typeId" :label="item.labelName" :value="item.typeId">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="上传文件">
          <el-upload class="avatar-uploader upload-zip" ref="uploadZip"
            action="https://api-t-2.upload.jquen.com/api/took/card/upload/zip" accept=".zip" :file-list="fileListZip"
            :on-progress="handProgress" :on-success="handleAvatarSuccessZip" :before-upload="beforeAvatarUploadZip"
            :on-change="handleChangeZip" :limit="1">
            <p class="tet-udl pointer">点击上传文件</p>
          </el-upload>
          <div class="co-66">*只能上传zip压缩包，且不超过200M</div>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" :rows="2" v-model="ruleForm.remark" placeholder="请输入备注"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
<script>
import commonTable from "@/components/common/commonTable";
import { materialAdd, materialQueryPage, materialTypeQueryPage, JqMaterialUpdate, JqMaterialDeleteById } from "@/api/drawingBed";
import { getDataTimeSec } from "@/utils";
export default {
  components: {
    commonTable,
  },
  data() {
    return {
      dialogVisible: false,
      ruleForm: {},
      rules: {},
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 1, //总条数
      isEdit: false,
      isUpdate: true,
      fileListZip: [],
      classList: []
    };
  },
  computed: {
    setDataTimeSec() {
      return (data) => getDataTimeSec(data);
    },
  },
  mounted() {
    this.getData();
    this.getClassList();
  },
  methods: {
    addModel() {
      this.dialogVisible = true;
      this.isEdit = false;
      this.fileListZip = [];
      this.ruleForm = {};
    },
    dalete(id) {
      JqMaterialDeleteById({ Bid: id }).then(res => {
        this.$message.success('删除成功！');
        this.getData();
      })
    },
    editModel(item) {
      this.isEdit = true;
      this.fileListZip=[{
        name:'zip',
        response:{
          result:[item.fileUrl]
        }
      }]
      this.ruleForm = JSON.parse(JSON.stringify(item));
      this.ruleForm.bId = this.ruleForm.id;
      this.ruleForm.dueTim = '';
      this.ruleForm.typeId=this.ruleForm.typeId*1
      this.dialogVisible = true;
    },
    /**@method 上传时回调 */
    handProgress(event, file, fileList) {
      if (this.isUpdate) {
        this.$message.closeAll();
        this.$message({
          message: "正在上传",
        });
      }
      this.isUpdate = false;
    },
    /**@method 上传成功 */
    handleAvatarSuccessZip(res, file) {
      this.$message.closeAll();
      this.isUpdate = true;
      if (res) {
        this.ruleForm.fileUrl = res.result[0];
      } else {
        this.$message.error('上传失败！')
      }
    },
    beforeAvatarUploadZip(file) {
      const isLt2M = file.size / 1024 / 1024 < 200;
      if (!isLt2M) {
        this.$message.error("zip压缩包大小不能超过 200MB!");
      }
      return isLt2M;
    },
    //上传文件事件
    handleChangeZip(file, fileList) {
      if (file.status != "ready") {
        return false;
      }
      this.beforeAvatarUploadZip(file, fileList);
      this.fileListZip = fileList;
    },
    submit() {
      console.log(this.fileListZip, 'fileListZip');
      console.log(this.ruleForm, 'ruleForm');
      if (!this.ruleForm.title) {
        this.$message.warning('请输入标题！');
        return;
      }
      if (!this.ruleForm.fileUrl) {
        this.$message.warning('请上传文件！');
        return;
      }
      if (this.isEdit) {
        JqMaterialUpdate(this.ruleForm).then(res => {
          if (res.code == 200) {
            this.$message.success('保存成功！');
            this.getData();
            this.dialogVisible = false;
          } else {
            this.$message.warning(res.message)
          }
        })
       } else {
        materialAdd(this.ruleForm).then(res => {
          if (res.code == 200) {
            this.$message.success('保存成功！');
            this.getData();
            this.dialogVisible = false;
          } else {
            this.$message.warning(res.message)
          }
        })
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    // 获取列表数据
    getData() {
      let data = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        title: this.titleValue,
      };
      this.loading = true;
      materialQueryPage(data).then((res) => {
        this.loading = false;
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    // 获取分类列表
    getClassList() {
      let data = {
        pageNum: 1,
        pageSize: 1000,
      };
      materialTypeQueryPage(data).then((res) => {
        this.classList = res.data.records;
      });
    },
  },
};
</script>
  
<style></style>