<template>
  <div class="">
    <el-card shadow="never">
      <el-button
        type="primary"
        @click="
          (dialogVisible = true), (ruleForm = {}), (isEdit = false)
        "
        >添加分类</el-button
      >
      <commonTable
        :tableData="tableData"
        :loading="loading"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        :currentPage="currentPage"
        :total="total"
      >
        <template v-slot:table>
          <el-table-column prop="typeId" align="center" label="编号" />
          <el-table-column prop="labelName" align="center" label="标题" />
          <el-table-column prop="count" align="center" label="应用数量" />
          <!-- <el-table-column prop="userName" align="center" label="排序" /> -->
          <el-table-column align="center" label="操作">
            <div slot-scope="scope">
              <el-button type="text" @click="openLog(scope.row, 1)"
                >设置</el-button
              >
              <el-popconfirm
                v-if="scope.row.count==0"
                class="ml-12"
                title="是否删除分类？"
                @confirm="openLog(scope.row, 2)"
              >
                <el-button slot="reference" type="text">删除</el-button>
              </el-popconfirm>
              <el-popconfirm
                v-else
                class="ml-12"
                title="该分类下存在应用，不可删除"
              >
                <el-button slot="reference" type="text">删除</el-button>
              </el-popconfirm>
            </div>
          </el-table-column>
        </template>
      </commonTable>
    </el-card>
    <el-dialog
      title="添加分类"
      :visible.sync="dialogVisible"
      width="50%"
      :modal-append-to-body="false"
      :destroy-on-close="true"
      center
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="78px"
      >
        <el-form-item label="分类名称" prop="title">
          <el-input
            v-model="ruleForm.labelName"
            placeholder="请输入分类名称"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
  
  <script>
import commonTable from "@/components/common/commonTable";
import { materialTypeAdd, materialTypeQueryPage,materialTypeUpdate,materialTypeDeleteById } from "@/api/drawingBed";
export default {
  components: {
    commonTable,
  },
  data() {
    return {
      dialogVisible: false,
      ruleForm: {},
      rules: {},
      tableData: [],
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 1, //总条数
      isEdit: false,
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    submit() {
      if (this.ruleForm.labelName) {
        if (!this.isEdit) {
          materialTypeAdd(this.ruleForm).then((res) => {
            if (res.code == 200) {
              this.$message.success("保存成功！");
              this.dialogVisible=false;
              this.getData();
            }
          });
        }else{
          materialTypeUpdate(this.ruleForm).then((res) => {
            if (res.code == 200) {
              this.$message.success("保存成功！");
              this.dialogVisible=false;
              this.getData();
            }
          });
        }
      }
    },
    getData() {
      let data = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
      };
      materialTypeQueryPage(data).then((res) => {
        this.tableData = res.data.records;
        this.total = res.data.total;
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.currentPage = val;
    },
    openLog(item, i) {
      if (i == 1) {
        this.ruleForm = item;
        this.isEdit = true;
        this.dialogVisible = true;
      }else if(i==2){
        materialTypeDeleteById({typeId:item.typeId}).then(res=>{
          if(res.code==200){
            this.$message.success('删除成功！')
            this.getData();
          }
        })
      }
    },
  },
};
</script>
  
  <style>
</style>