<template>
    <div>
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="模型" name="first">
          <Tab1 v-if="activeName == 'first'"></Tab1>
        </el-tab-pane>
        <el-tab-pane label="模型分类" name="second">
          <Tab2 v-if="activeName == 'second'"></Tab2>
        </el-tab-pane>
      </el-tabs>
    </div>
  </template>
  
  <script>
  import Tab1 from "./components/tab1.vue";
  import Tab2 from "./components/tab2.vue";
  export default {
    data() {
      return {
        activeName: "first", //tabs页
      };
    },
    components: {
      Tab1,
      Tab2,
    },
    filters: {},
    computed: {},
    watch: {},
    created() {},
    mounted() {},
    beforeDestroy() {},
    methods: {
      handleClick(tab) {
        const { name } = tab;
        this.activeName = name;
      },
    },
  };
  </script>
  
  <style lang="scss" scoped>
  </style>
  